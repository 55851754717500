import type { Tela } from '~/types/tela'

export function useSchemaObject() {
    function flatSchema(schema: Tela.SchemaObject, options: { expandArrays: boolean } = { expandArrays: true }) {
        const properties: Tela.FlatObjectProperty[] = []
        const arrayProperties = listArrayProperties(schema)

        function extractProperties(schema: Tela.SchemaObject, prefix = '') {
            for (const key in schema) {
                if (schema[key].type === 'array' && options.expandArrays) {
                    if (schema[key].items.type !== 'object') {
                        properties.push({
                            key: prefix ? `${prefix}.${key}` : key,
                            value: schema[key],
                        })
                    }
                    else {
                        extractProperties(schema[key].items.properties, prefix ? `${prefix}.${key}` : key)
                    }
                }
                else if (schema[key].type !== 'object') {
                    properties.push({
                        key: prefix ? `${prefix}.${key}` : key,
                        value: schema[key],
                    })
                }
                else {
                    extractProperties(schema[key].properties, prefix ? `${prefix}.${key}` : key)
                }
            }
        }

        extractProperties(schema)

        return {
            properties,
            arrayProperties,
        }
    }

    function listArrayProperties(schema: Tela.SchemaObject): string[] {
        const arrayProperties: string[] = []
        function searchArrayProperties(schema: Tela.SchemaObject, prefix = '') {
            for (const key in schema) {
                if (schema[key].type === 'array') {
                    arrayProperties.push(prefix ? `${prefix}.${key}` : key)
                    if (schema[key].items.type === 'object') {
                        searchArrayProperties(schema[key].items.properties, prefix ? `${prefix}.${key}` : key)
                    }
                }
                else if (schema[key].type === 'object') {
                    searchArrayProperties(schema[key].properties, prefix ? `${prefix}.${key}` : key)
                }
            }
        }

        searchArrayProperties(schema)
        return arrayProperties
    }

    function flatOutput(data: Record<string, any>) {
        const properties: Tela.FlatObjectProperty[] = []
        function extractProperties(data: Record<string, any>, prefix = '') {
            for (const key in data) {
                // only consider an array as present if has items
                if (Array.isArray(data[key]) && data[key].length > 0) {
                    properties.push({
                        key: prefix ? `${prefix}.${key}` : key,
                        value: data[key],
                    })
                }
                else if (typeof data[key] !== 'object') {
                    properties.push({
                        key: prefix ? `${prefix}.${key}` : key,
                        value: data[key],
                    })
                }
                else {
                    extractProperties(data[key], prefix ? `${prefix}.${key}` : key)
                }
            }
        }

        extractProperties(data)

        return {
            properties,
        }
    }

    return {
        flatSchema,
        flatOutput,
        listArrayProperties,
    }
}
