import { Type } from '@sinclair/typebox'
import { type SQL, sql } from 'drizzle-orm'
import type { CursiveStreamDelta } from 'cursive'
import { createClerkClient } from '@clerk/clerk-sdk-node'
import type { Tela } from '../../application/types/tela'

export const metricOperators = ['avg', 'count', 'max', 'min', 'sum'] as const
export type MetricOperators = typeof metricOperators[number]

export function ifSQL<T>(condition: any, statement: T) {
    return (condition ? statement : sql`TRUE`) as SQL
}

export async function generateInternalJWT(sessionId: string) {
    const clerkClient = createClerkClient({ secretKey: Bun.env.CLERK_SECRET_KEY })

    const token = await clerkClient.sessions.getToken(sessionId, 'tela-api-internal-jwt')

    return `Bearer ${token.jwt}`
}

export const TelaSearchFilterSchema = Type.Object({
    projectId: Type.Optional(Type.String()),
    start: Type.String({ format: 'date-time' }),
    end: Type.String({ format: 'date-time' }),
    limit: Type.Optional(Type.String()),
    offset: Type.Optional(Type.String()),
})

export const ChatMessagesSchema = Type.Union([
    Type.Object({
        role: Type.Union([
            Type.Literal('user'),
            Type.Literal('system'),
            Type.Literal('assistant'),
            Type.Literal('function'),
        ]),
        content: Type.Union([
            Type.String(),
            Type.Any(),
        ]),
    }),
])

export function trim(str: TemplateStringsArray, ...args: string[]) {
    let result = str[0]
    for (let i = 0; i < args.length; i++)
        result += args[i] + str[i + 1]

    const lines = result.split('\n')
    let minIndent = Number.POSITIVE_INFINITY
    for (const line of lines) {
        const indent = line.search(/\S/)
        if (indent !== -1)
            minIndent = Math.min(minIndent, indent)
    }

    result = ''
    for (const line of lines)
        result += `${line.slice(minIndent)}\n`

    return result.trim()
}

export function removeEmptyValues<T extends Record<string, any>>(obj: T): Partial<T> {
    return Object.entries(obj).reduce((a, [k, v]) => {
        if (v !== null && v !== undefined) {
            (a as T)[k as keyof T] = v
        }
        return a
    }, {} as Partial<T>)
}

export function createEnumFromObject<T extends Record<string | symbol, any>>(obj: T) {
    return Object.keys(obj).reduce<{ [K in keyof T as K extends string ? K : never]: K }>((acc, key) => {
        if (typeof key === 'string')
            acc[key as keyof typeof acc] = key as keyof typeof acc
        return acc
    }, {} as any) as any as { [K in keyof T as K extends string ? K : never]: K }
}

export type Choice = CursiveStreamDelta & {
    message?: any
}

export function resolveContent(content: Tela.Content, toolCalls?: any[]): { resolvedContent: Tela.Content, hasStructuredOutput: boolean } {
    const hasStructuredOutput = Boolean(!(content && typeof content === 'string') && toolCalls)

    if (!hasStructuredOutput)
        return { resolvedContent: content, hasStructuredOutput }

    // Extracting structured output
    const toolCallsMapped = toolCalls?.map((call: any) => ({
        name: call.name,
        arguments: call.arguments,
    }))

    return { resolvedContent: toolCallsMapped ?? null, hasStructuredOutput }
}

export function buildPromptChatMessages(messages: Tela.ChatMessage[] = []): Tela.ChatMessage[] {
    return messages.map(({ content, role, toolCalls }, index) => {
        const { resolvedContent, hasStructuredOutput } = resolveContent(content, toolCalls)

        return {
            content: resolvedContent,
            hasStructuredOutput,
            role,
            index,
        }
    })
}
