import { type Static, Type } from '@sinclair/typebox'
import { boolean, index, json, pgTable, text, timestamp, uuid, varchar } from 'drizzle-orm/pg-core'
import { relations } from 'drizzle-orm'
import { overrideType, tableToSchema } from './shared'
import { prompt } from './prompt'
import { evaluatorRun } from './evaluator-run'
import { promptVersion } from './prompt-version'
import { score } from './score'

export const evaluator = pgTable('evaluator', {
    id: uuid('id').primaryKey().defaultRandom(),
    key: text('key').default(''),
    configuration: json('configuration').default({}),
    configurationHash: varchar('configuration_hash'),
    promptId: uuid('prompt_id').notNull().references(() => prompt.id, { onDelete: 'cascade' }),
    promotedPromptVersionId: uuid('promoted_prompt_version_id').references(() => promptVersion.id, { onDelete: 'cascade' }),
    active: boolean('active').notNull().default(true),
    title: varchar('title', { length: 256 }),

    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
    deletedAt: timestamp('deleted_at'),
}, (table) => {
    return {
        idxEvaluatorPromptId: index('idx_evaluator_prompt_id').on(table.promptId),
        idxEvaluatorPromptIdDeletedAt: index('idx_evaluator_prompt_id_deleted_at').on(table.promptId, table.deletedAt),

        idxEvaluatorDeletedAt: index('idx_evaluator_deleted_at').on(table.deletedAt),
    }
})

export const evaluatorRelations = relations(evaluator, ({ one, many }) => ({
    prompt: one(prompt, {
        fields: [evaluator.promptId],
        references: [prompt.id],
    }),
    promptVersion: one(promptVersion, {
        fields: [evaluator.promotedPromptVersionId],
        references: [promptVersion.id],
    }),
    evaluatorRun: many(evaluatorRun),
    score: many(score),
}))

const { selectSchema, insertSchema } = tableToSchema(evaluator)

export const selectEvaluatorSchema = overrideType(selectSchema, {
    configuration: Type.Record(Type.String(), Type.Any()),
})

export const insertEvaluatorSchema = overrideType(
    // @ts-expect-error no idea why this error is going on, seems to be an overly complex type

    Type.Omit(insertSchema, ['createdAt', 'updatedAt', 'id', 'deletedAt']),
    {
        configuration: Type.Record(Type.String(), Type.Any()),
    },
)
export const updateEvaluatorSchema = Type.Partial(Type.Pick(insertEvaluatorSchema, ['configuration', 'active', 'title']))
export const fetchEvaluatorSchema = Type.Composite(
    [
        Type.Pick(selectSchema, ['promptId']), // Required filters
        Type.Partial(Type.Pick(selectSchema, ['promotedPromptVersionId', 'active'])), // Optional filters
    ],
)

export type Evaluator = Static<typeof selectEvaluatorSchema>
export type CreateEvaluatorPayload = Static<typeof insertEvaluatorSchema>
export type UpdateEvaluatorPayload = Static<typeof updateEvaluatorSchema>
