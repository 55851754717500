import Minisearch from 'minisearch'
import { useTemporaryFileUpload } from '../services/temporary-file-upload'
import { isFileTypeParseSupported } from '../services/parse-api'
import { InvalidCompletionVariablesError, InvalidSchema } from '../errors'
import type { CompletionRequest } from '../schemas/completion'

export async function resolveFileVariablesForAsyncCompletion({ variables, workspaceId, envs }: { variables: Record<string, string | { file_url: string }>; workspaceId: string, envs: { R2_ACCESS_KEY_ID: string, R2_SECRET_ACCESS_KEY: string } }) {
    const resolvedVariables: Record<string, any> = {}

    for (const variable in variables) {
        const variableValue = variables[variable]

        if (variableValue === undefined || variableValue === null)
            continue

        if (typeof variableValue === 'string') {
            resolvedVariables[variable] = variableValue
        }

        else if (typeof variableValue === 'object' && 'file_url' in variableValue) {
            resolvedVariables[variable] = {
                file_url: await uploadAndGetPublicUrl({ envs, fileUrl: variableValue.file_url, workspaceId }),
            }
        }
        else {
            throw new InvalidSchema(`Invalid variable value for variable ${variable}. Expected string or object with file_url property.`)
        }
    }

    return resolvedVariables
}

async function uploadAndGetPublicUrl({ fileUrl, workspaceId, envs }: { fileUrl: string; workspaceId: string, envs: { R2_ACCESS_KEY_ID: string, R2_SECRET_ACCESS_KEY: string } }) {
    let parseFileURL: string | null = null
    const fileType = fileUrl.match(/data:([^;]+);base64,/)?.[1] ?? ''

    if (fileUrl.startsWith('https://')) {
        parseFileURL = fileUrl
    }
    else if (isFileTypeParseSupported(fileType)) {
        const base64 = fileUrl.split(',')[1]
        // eslint-disable-next-line node/prefer-global/buffer
        const buffer = Buffer.from(base64, 'base64')
        const blob = new Blob([buffer], { type: fileType })
        const { uploadBlob } = useTemporaryFileUpload({
            accessKeyId: envs.R2_ACCESS_KEY_ID,
            secretAccessKey: envs.R2_SECRET_ACCESS_KEY,
        })
        const { downloadUrl } = await uploadBlob(blob, workspaceId)
        parseFileURL = downloadUrl
    }
    else {
        throw new Error(`Invalid file submitted. Unsupported file type: ${fileType}.`)
    }

    return parseFileURL
}

export function checkMissingVariables(completionInputs: CompletionRequest[], variables: string[]) {
    const missingVariables: Record<number, string[]> = completionInputs.reduce((acc, completionInput, index) => {
        if (!completionInput.variables) {
            acc[index] = variables
        }
        else {
            const missing = variables.filter(variable => !(variable in completionInput.variables!))
            if (missing.length > 0) {
                acc[index] = missing
            }
        }
        return acc
    }, {} as Record<number, string[]>)

    if (Object.keys(missingVariables).length > 0) {
        const missingDetails = Object.entries(missingVariables)
            .map(([index, vars]) => `Completion payload at index ${index} is missing variables: ${vars.join(', ')}`)
            .join('; ')

        return { hasMissingVariables: true, details: missingDetails }
    }

    return { hasMissingVariables: false, details: 'Every completion payload has all required variables' }
}

export function validateVariables(variables: string[], payloadVariables: Record<string, string | { file_url: string }>) {
    for (const variable in payloadVariables) {
        const lowerCaseVariables = variables.map(v => v.toLowerCase())

        if (!lowerCaseVariables.includes(variable.toLowerCase())) {
            const closestVariable = getClosestVariable(variable, variables)
            const closestVariableText = closestVariable ? ` Did you mean "${closestVariable}"?` : ''
            throw new InvalidCompletionVariablesError(`Variable ${variable} is not defined in the prompt version.${closestVariableText}`)
        }
    }
}

export function getClosestVariable(variable: string, variables: string[]) {
    const miniSearch = new Minisearch({
        fields: ['id'],
        storeFields: ['id'],
    })

    // Index the valid variables
    miniSearch.addAll(variables.map(variable => ({ id: variable })))

    // Search for the closest variable
    const results = miniSearch.search(variable, { fuzzy: 0.2 })

    // Return the closest variable
    return results[0]?.id ?? null
}

export function insertVariablesIntoContent(content: string, inputVariables: Record<string, string>) {
    const lowerCaseVariables = {} as Record<string, string>
    for (const variable in inputVariables) {
        lowerCaseVariables[variable.toLowerCase()] = inputVariables[variable]
    }

    const availableVariables = parseVariablesFromMarkdown(content)

    const variableRegex = new RegExp(`{(${availableVariables.join('|')})}`, 'ig')
    const resolvedContent = content.replace(variableRegex, (_match, variable) => {
        return lowerCaseVariables[variable.toLowerCase()] ?? ''
    })

    return resolvedContent
}

/**
 * Extracts unique variable names from the provided markdown content.
 * Variables are identified by the format `{variableName}` and the extraction is case-insensitive.
 * When multiple casing options are present for the same variable, the first occurrence is retained.
 *
 * @param markdownContent - The markdown string to parse for variables.
 * @returns An array of unique variable names found in the markdown content.
 */
export function parseVariablesFromMarkdown(markdownContent: string): string[] {
    // Regular expression to match variables in the format {variableName}, case-insensitive
    const VARIABLE_REGEX = /\{([a-zA-Z_]\w*)\}/gi

    // Map to store unique variables with their original casing
    const uniqueVariablesMap: Map<string, string> = new Map()

    // Use matchAll to get all matches in the markdown content
    const matches = markdownContent.matchAll(VARIABLE_REGEX)

    // Iterate over each match
    for (const match of matches) {
        const originalVariable = match[1] // Extracted variable name with original casing
        const variableKey = originalVariable.toLowerCase() // Key for case-insensitive comparison

        // If the variable hasn't been added yet, add it to the map
        if (!uniqueVariablesMap.has(variableKey)) {
            uniqueVariablesMap.set(variableKey, originalVariable)
        }
    }

    // Return an array of unique variable names preserving the original casing
    return Array.from(uniqueVariablesMap.values())
}
