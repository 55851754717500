import type { Static } from '@sinclair/typebox'
import { Type } from '@sinclair/typebox'
import { index, jsonb, pgTable, text, timestamp, uuid } from 'drizzle-orm/pg-core'
import { relations } from 'drizzle-orm'
import type { Tela } from '../../../../application/types/tela'
import { ChatMessagesSchema } from '../../shared'
import { prompt } from './prompt'
import { tableToSchema } from './shared'
import { generation } from './generation'

export const testCase = pgTable('test_case', {
    id: uuid('id').primaryKey().defaultRandom(),
    title: text('title').notNull().default('Untitled Test Case'),
    messages: jsonb('messages').default([]).$type<Tela.ChatMessage[]>(),
    variables: jsonb('variables').default({}).$type<Record<string, string>>(),
    variablesRichContent: jsonb('variables_rich_content').default({}).$type<Record<string, string>>(),
    files: jsonb('files').default([]).$type<string[]>(),
    promptId: uuid('prompt_id').notNull().references(() => prompt.id, { onDelete: 'cascade' }),
    expectedOutput: text('expected_output'),
    answers: jsonb('answers').default({}).$type<Record<Tela.SchemaAttribute.Key, Tela.SchemaAttribute.Answers>>(),
    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
    deletedAt: timestamp('deleted_at'),
}, (table) => {
    return {
        idxTestCasePromptId: index('idx_test_case_prompt_id').on(table.promptId),
        idxTestCasePromptIdDeletedAt: index('idx_test_case_prompt_id_deleted_at').on(table.promptId, table.deletedAt),

        idxTestCaseDeletedAt: index('idx_test_case_deleted_at').on(table.deletedAt),
    }
})

export const testCaseRelations = relations(testCase, ({ one, many }) => ({
    prompt: one(prompt, {
        fields: [testCase.promptId],
        references: [prompt.id],
    }),
    generations: many(generation),
}))

const { selectSchema, insertSchema } = tableToSchema(testCase)

const createTestCasePayloadSchema = Type.Composite([
    Type.Omit(
        insertSchema,
        ['createdAt', 'updatedAt', 'id', 'variables', 'files', 'answers'],
    ),
    Type.Object({
        messages: Type.Union([Type.Array(ChatMessagesSchema), Type.Null()]),
        variables: Type.Optional(
            Type.Union([
                Type.Record(Type.String(), Type.String()),
                Type.Null(),
            ]),
        ),
        files: Type.Optional(Type.Union([Type.Array(Type.String()), Type.Null()])),
        variablesRichContent: Type.Optional(
            Type.Union([
                Type.Record(Type.String(), Type.String()),
                Type.Null(),
            ]),
        ),
        answers: Type.Optional(Type.Record(Type.String(), Type.Object({
            good: Type.Array(Type.Any()),
            bad: Type.Array(Type.Any()),
            evals: Type.Array(Type.Any()),
        }))),
    }),
])

export const createTestCaseSchema = Type.Union([
    createTestCasePayloadSchema,
    Type.Array(createTestCasePayloadSchema),
])

export const updateTestCaseSchema = Type.Partial(createTestCasePayloadSchema)
export const selectTestCaseSchema = selectSchema

export type TestCase = typeof testCase.$inferSelect
export type CreateTestCasePayload = Static<typeof createTestCaseSchema>
export type UpdateTestCasePayload = Partial<Static<typeof createTestCasePayloadSchema>>
