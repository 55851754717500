import { type Static, Type } from '@sinclair/typebox'
import { boolean, jsonb, pgEnum, pgTable, smallint, text, timestamp, uuid, varchar } from 'drizzle-orm/pg-core'
import { relations } from 'drizzle-orm'
import type { Tela } from '../../../../application/types/tela'
import { ChatMessagesSchema } from '../../shared'
import { testCase } from './test-case'
import { promptVersion } from './prompt-version'
import { evaluatorRun } from './evaluator-run'
import { overrideType, tableToSchema } from './shared'
import type { CompletionEventEnvironment } from './completion-event'
import { selectGenerationFeedbackSchema } from './generation-feedback'

export const generationType = pgEnum('generation_type', ['text', 'image', 'audio', 'json', 'function'])

export const generation = pgTable('generation', {
    id: uuid('id').primaryKey().defaultRandom(),
    content: text('content').notNull(),
    type: generationType('type').notNull(),
    testCaseId: uuid('test_case_id').references(() => testCase.id, { onDelete: 'cascade' }),
    promptVersionId: uuid('prompt_version_id').notNull().references(() => promptVersion.id, { onDelete: 'cascade' }),
    inputMessages: jsonb('input_messages').default([]).$type<Tela.ChatMessage[]>(),
    environment: varchar('environment').notNull().$type<CompletionEventEnvironment>(),

    resolvedInput: text('resolved_input'),
    // 1 = correct, 0 = incorrect, null = not yet graded
    feedback: smallint('feedback'),
    attributeFeedback: jsonb('attribute_feedback').default({}).$type<Record<Tela.SchemaAttribute.Key, 0 | 1>>(),
    validations: jsonb('validations').default({}).$type<Record<Tela.SchemaAttribute.Key, Tela.SchemaAttribute.Validation>>(),
    archived: boolean('archived').notNull().default(false),
    promptInput: jsonb('prompt_input').default([]).$type<Tela.ChatMessage[]>(),
    variables: jsonb('variables').default({}).$type<Record<string, string>>(),

    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
    deletedAt: timestamp('deleted_at'),
})

export const generationRelations = relations(generation, ({ one, many }) => ({
    testCase: one(testCase, {
        fields: [generation.testCaseId],
        references: [testCase.id],
    }),
    evaluatorRun: many(evaluatorRun),
    promptVersion: one(promptVersion, {
        fields: [generation.promptVersionId],
        references: [promptVersion.id],
    }),
}))

const feedbackSchema = Type.Optional(Type.Union([Type.Literal(1), Type.Literal(0), Type.Null()]))
export type Feedback = Static<typeof feedbackSchema>

const { selectSchema, insertSchema } = tableToSchema(generation)

const validationsSchema = Type.Record(Type.String(), Type.Object({
    type: Type.Union([Type.Literal('good'), Type.Literal('bad'), Type.Literal('warning'), Type.Literal('missing')]),
    message: Type.String(),
}))

const insertSchemaWithConstraints = overrideType(insertSchema, {
    feedback: feedbackSchema,
    variables: Type.Optional(
        Type.Union([
            Type.Record(Type.String(), Type.String()),
            Type.Null(),
        ]),
    ),
    promptInput: Type.Array(Type.Intersect([
        Type.Object({
            role: Type.Literal('system'),
            content: Type.String(),
        }),
    ])),
    inputMessages: Type.Array(ChatMessagesSchema),
    validations: Type.Optional(validationsSchema),
    attributeFeedback: Type.Optional(Type.Record(Type.String(), Type.Union([Type.Literal(0), Type.Literal(1)]))),
})
export const insertGenerationSchema = Type.Union([
    Type.Omit(insertSchemaWithConstraints, [
        'environment',
        'createdAt',
        'updatedAt',
        'deletedAt',
        'attributeFeedback',
    ]),
    Type.Intersect([
        Type.Pick(insertSchemaWithConstraints, ['content', 'feedback', 'promptVersionId', 'type']),
    ]),
])
export const insertTestCaseGenerationSchema = Type.Intersect([
    Type.Omit(insertSchemaWithConstraints, [
        'environment',
        'feedback',
        'createdAt',
        'updatedAt',
        'deletedAt',
        'attributeFeedback',
    ]),
    Type.Object({
        testCaseId: Type.String(),
        resolvedInput: Type.String(),
        inputMessages: Type.Array(ChatMessagesSchema),
    }),
])
export const selectGenerationSchema = selectSchema
export const selectTestCaseGenerationSchema = overrideType(selectSchema, {
    testCaseId: Type.String(),
    inputMessages: Type.Array(ChatMessagesSchema),
    variables: Type.Optional(
        Type.Union([
            Type.Record(Type.String(), Type.String()),
            Type.Null(),
        ]),
    ),
    userFeedback: Type.Optional(selectGenerationFeedbackSchema),
    attributeFeedback: Type.Optional(Type.Record(Type.String(), Type.Union([Type.Literal(0), Type.Literal(1)]))),
    validations: Type.Optional(validationsSchema),
})
export const updateGenerationSchema = Type.Partial(
    Type.Object({
        content: Type.Optional(Type.String()),
        feedback: Type.Optional(feedbackSchema),
        promptVersionId: Type.Optional(Type.String()),
        type: Type.Optional(Type.Union([
            Type.Literal('text'),
            Type.Literal('image'),
            Type.Literal('audio'),
            Type.Literal('json'),
            Type.Literal('function'),
        ])),
        deletedAt: Type.Optional(Type.Date()),
        attributeFeedback: Type.Optional(Type.Record(Type.String(), Type.Union([Type.Literal(0), Type.Literal(1)]))),
        validations: Type.Optional(validationsSchema),
    }),
)

export type Generation = Static<typeof selectGenerationSchema>
export type TestCaseGeneration = Static<typeof selectTestCaseGenerationSchema>
export type CreateGenerationPayload = Static<typeof insertGenerationSchema>
export type CreateTestCaseGenerationPayload = Static<typeof insertTestCaseGenerationSchema>
export type UpdateGenerationPayload = Static<typeof updateGenerationSchema>
