import { pgTable, text, timestamp, uuid, varchar } from 'drizzle-orm/pg-core'
import { relations } from 'drizzle-orm'
import { type Static, Type } from '@sinclair/typebox'
import { promptVersion } from './prompt-version'
import { generation } from './generation'
import { tableToSchema } from './shared'

export const generationFeedback = pgTable('generation_feedback', {
    id: uuid('id').primaryKey().defaultRandom(),
    content: text('content').notNull(),
    generationId: uuid('generation_id').notNull().references(() => generation.id, { onDelete: 'cascade' }),
    promptVersionId: uuid('prompt_version_id').notNull().references(() => promptVersion.id, { onDelete: 'cascade' }),
    createdBy: varchar('created_by').notNull(),
    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
    deletedAt: timestamp('deleted_at'),
})

export const generationRelations = relations(generationFeedback, ({ one }) => ({
    generation: one(generation, {
        fields: [generationFeedback.generationId],
        references: [generation.id],
    }),
    promptVersion: one(promptVersion, {
        fields: [generationFeedback.promptVersionId],
        references: [promptVersion.id],
    }),
}))

const { selectSchema, insertSchema } = tableToSchema(generationFeedback)

export const selectGenerationFeedbackSchema = selectSchema
export const insertGenerationFeedbackSchema = Type.Pick(insertSchema, ['content', 'generationId', 'promptVersionId'])
export const updateGenerationFeedbackSchema = Type.Pick(insertGenerationFeedbackSchema, ['content'])

export type GenerationFeedback = Static<typeof selectGenerationFeedbackSchema>
export type CreateGenerationFeedbackPayload = Static<typeof insertGenerationFeedbackSchema>
export type UpdateGenerationFeedbackPayload = Static<typeof updateGenerationFeedbackSchema>
