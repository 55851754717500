import { Type } from '@sinclair/typebox'

export const messageSchema = Type.Object({
    role: Type.String(),
    content: Type.Union([
        Type.String(),
        Type.Object({
            type: Type.Literal('text'),
            text: Type.String(),
        }),
        Type.Object({
            type: Type.Literal('image_url'),
            image_url: Type.Object({
                url: Type.String(),
                detail: Type.Optional(Type.Union([
                    Type.Literal('auto'),
                    Type.Literal('high'),
                    Type.Literal('low'),
                ])),
            }),
        }),
    ]),
    function_call: Type.Optional(Type.Object({
        name: Type.String(),
        arguments: Type.String(),
    })),
})

export const variablesSchema = Type.Record(
    Type.String(),
    Type.Union([
        Type.String(),
        Type.Object({
            file_url: Type.String(),
        }),
    ]),
)

export const completionRequestSchema = Type.Object({
    version_id: Type.Optional(Type.String({ format: 'uuid' })), // prompt version Id
    canvas_id: Type.Optional(Type.String({ format: 'uuid' })), // prompt Id / canvas Id
    application_id: Type.Optional(Type.String({ format: 'uuid' })),
    webhook_url: Type.Optional(Type.String()),
    variables: Type.Optional(variablesSchema),
    input: Type.Optional(Type.String()),
    messages: Type.Optional(Type.Array(messageSchema)),
    stream: Type.Optional(Type.Boolean()),
    // We simulate a non-streaming response when this is passed, through streaming
    // To keep the connection alive.
    // This is needed because the providers API's timeout after 100 seconds
    long_response: Type.Optional(Type.Boolean()),
    override: Type.Optional(Type.Any()),
})

export type CompletionRequestMessage = typeof messageSchema.static
export type CompletionRequest = typeof completionRequestSchema.static
